import Flickity from "flickity";
require('flickity-imagesloaded');

  $(document).on('turbo:load', function() {
    var browser_width = $(window).width()
    // Set breakpoints
    var xlarge_screen = (browser_width >= 1440)
    var large_screen = (browser_width >= 992 && browser_width < 1440)
    var medium_screen = (browser_width >= 768 && browser_width < 992)
    var small_screen = (browser_width < 768)

    // Set images width and carousel alignment
    $.fn.carousel_slides = function() {
      var js_carousel = $(this).find('.js_carousel')
      var videos_count = js_carousel.data('count')
      var id = "#" + $(this).attr("id")

     // SET NUMBER OF SLIDES TO BE SHOWN
     //  Default values
      var slides_xl = 5
      var slides_lg = 4
      var slides_md = 3
      var slides_sm = 2

     //  Custom values
      if ( id == "#trainers" ) {
        var slides_xl = 8
        var slides_lg = 6
        var slides_md = 4
        var slides_sm = 3
      }

      // calculate images container width
      if (small_screen) {
        var image_wrapper_width = (browser_width - 0) / slides_sm
      }
      if (medium_screen) {
        var image_wrapper_width = (browser_width - 16) / slides_md
      }
      if (large_screen) {
        var image_wrapper_width = (browser_width - 128) / slides_lg
      }
      if (xlarge_screen) {
        var image_wrapper_width = (browser_width - 128) / slides_xl
      }


      // images width
      $(id + ' .carousel-child').css("width", image_wrapper_width)

      //carousel alignment
      var alignment = 'center'
      if ( id == "#trainers" ) {
        var alignment = 'left'
      }
      if (medium_screen && videos_count < slides_md) {
        var alignment = 'left'
        js_carousel.css("padding-left", 8)
      }
      if (large_screen && videos_count < slides_lg) {
        var alignment = 'left'
        js_carousel.addClass('js_left_padding')
      }
      if (xlarge_screen && videos_count < slides_xl) {
        var alignment = 'left'
        js_carousel.addClass('js_left_padding')
      }

    // Flickity settings
    if(js_carousel) {
      new Flickity(js_carousel[0],{
        imagesLoaded: true,
        groupCells: true,
        pageDots: false,
        cellAlign: alignment
        });
      }
    };

    // assoc/show.html.erb
    $('.video-row').each(function(i, obj) {
      $(this).carousel_slides();
    });

   // categories/show.html.erb
   $('.category_sliders').each(function(i, obj) {
     $(this).carousel_slides();
   });
    // worlds/show.html.erb
   $('.world-video-row').each(function(i, obj) {
     $(this).carousel_slides();
   });
    // worlds/show.html.erb trainers section
    $('.world-trainer-row').each(function(i, obj) {
      $(this).carousel_slides();
    });
  });
