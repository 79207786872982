import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["select"]

  change(e) {
    const base_path = e.params.path
    const selectedScheduleId = this.selectTarget.value
    Turbo.visit(`${e.params.path}?schedule_id=${selectedScheduleId}`, { action: "replace" })
  }
}
